import {
	DeactivateRoutineRequest,
	MaterialRoutineMaster,
	MaterialRoutineType,
	PostResponse,
	SaveMaterialRoutineRequest,
} from '../types/routine.types';
import axiosInstance from '../utils/axios-instance';

// #region GET
export const getMaterialRoutines = async (): Promise<MaterialRoutineType[]> => {
	const url: string = `/MaterialIntegrityRoutine/GetMaterialRoutines`;
	return axiosInstance.get(url);
};

export const getMatRoutineMaster = async (): Promise<MaterialRoutineMaster> => {
	const url: string = `/MaterialIntegrityRoutine/GetMaster`;
	return axiosInstance.get(url);
};

export const exportData = async (): Promise<any> => {
	const url: string = `/MaterialIntegrityRoutine/ExportMaterialRoutine`;
	return axiosInstance.get(url, { responseType: 'arraybuffer' });
};
// #endregion

// #region POST
export const saveMaterialRoutine = async (request: SaveMaterialRoutineRequest): Promise<PostResponse> => {
	const url: string = `/MaterialIntegrityRoutine/SaveMaterialIntegrityRoutine`;
	return await axiosInstance.post(url, request);
};
// #endregion

// #region PUT
export const stopRoutine = async (request: DeactivateRoutineRequest): Promise<PostResponse> => {
	const url: string = `/MaterialIntegrityRoutine/StopRule?ruleId=${request.ruleId}&endDate=${request.endDate}`;
	return await axiosInstance.put(url);
};
// #endregion
